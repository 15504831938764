.notes {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notes__title {
  font-size: 24px;
  font-weight: 700;
}

.note {
  width: 90%;
  max-height: 50px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 10px;
}

.note__input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid black;
  border-radius: 10px;
  line-height: 22px;
  font-size: 18px;
  direction: rtl;
  width: 75%;
  margin: 0 auto;
  resize: none;
}

.note__input:hover {
  background-color: rgba(0, 0, 0, 0.1);
  transition: background-color 200ms linear;
}

.note__input:focus {
  border: 2px solid black;
  outline: none;
}

.note__input:disabled {
  color: black;
}

.note__input_edit {
  border-bottom: 2px solid cornflowerblue;
}

.note__delete {
  cursor: pointer;
  font-size: 26px;
}

.note__delete:hover {
  color: red;
  transform: scale(1.6);
  transition: color 200ms ease-out, transform 200ms ease-out;
}
