.user-form {
  background-color: whitesmoke;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  opacity: 0;
  max-height: 0;
  max-width: 400px;
  overflow: hidden;
  transition: all 200ms linear;
}

.user-form_open {
  max-height: fit-content;
  border: 10px solid grey;
  padding: 20px 10px;
  opacity: 1;
}

.user-form__notice {
  direction: rtl;
  color: black;
  font-size: 14px;
  width: 100%;
}

.user-form__input {
  font-size: 30px;
  border: none;
  border-radius: 20px;
  width: 30vw;
  max-width: 300px;
  min-width: 240px;
  padding: 15px;
  transition: all 200ms linear;
}

.user-form__input:hover {
  background-color: rgb(0, 0, 0, 0.1);
}

.user-form__submit {
  background-color: white;
  border: 5px solid grey;
  border-radius: 20px;
  font-size: 30px;
  margin: 0 auto;
  padding: 5px 25px;
  cursor: pointer;
  transition: all 100ms linear;
}

.user-form__submit:hover {
  background-color: floralwhite;
}

.user-form__submit:active {
  box-shadow: 5px 5px 1px rgb(0, 0, 0, 0.3) inset;
  padding: 10px 20px 0 30px;
}

.user-form__submit_loading {
  animation: loading 1s linear infinite;
}

.user-form__error {
  font-size: small;
  color: red;
}

.user-form__success {
  font-size: small;
  color: green;
}

@keyframes loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
