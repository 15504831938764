.locations {
  display: grid;
  direction: rtl;
  grid-template-columns: repeat(8, 1fr);
  overflow: scroll;
  width: 90%;
  margin: auto;
  padding: 5px;
  gap: 5px 10px;
}

.locations__title {
  background-color: white;
  border: 2px solid #808080;
  border-radius: 10px;
  font-size: 20px;
  width: 75px;
  margin: auto;
}

.location__title {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
}

.location__textarea {
  width: 25vw;
  max-width: 300px;
  min-width: 100px;
  height: 60px;
  resize: none;
  border: 1px solid #808080;
  border-radius: 10px;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
}

.location__textarea:disabled {
  color: black;
}

.location__textarea_name {
  background-color: floralwhite;
  width: 15vw;
  max-width: 150px;
  margin: 0 auto;
}

.location__textarea_edit {
  border: 2px solid cornflowerblue;
}
