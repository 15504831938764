.workers {
  display: grid;
  direction: rtl;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 50px auto auto;
  justify-items: center;
  align-items: center;
  margin: 30px auto;
  padding: 10px;
  width: 90%;
  max-height: 80vh;
  gap: 5px;
  overflow: scroll;
  position: relative;
}

.workers_single {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.workers_single__name {
  margin: 0 0 20px;
  font-size: 50px;
  font-weight: 500;
  text-shadow: 2px 2px lightblue, -2px 2px lightblue, -2px -2px lightblue,
    2px -2px lightblue;
}

.workers_single__days {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: scroll;
}

.workers_single__day {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  overflow: scroll;
}

.workers_single__text {
  width: 200px;
  height: 100px;
  border: 1px solid gray;
  border-radius: 10px;
  margin: 0;
}

.workers_single__text:first-of-type {
  background-color: floralwhite;
  width: 100px;
  height: 36px;
  line-height: 36px;
}

.workers_single__text:last-of-type {
  background-color: whitesmoke;
}

.day {
  background-color: white;
  width: 100px;
  height: 35px;
  border: 2px solid #808080;
  border-radius: 10px;
  position: sticky;
  top: 10px;
  font-size: 20px;
  line-height: 30px;
}

.placeholder {
  background-color: transparent;
  border: none;
  width: 0;
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.worker__title {
  grid-column: 1 / 2;
  grid-row: span 2;
  position: relative;
  display: flex;
  align-items: center;
}

.worker__name {
  background-color: floralwhite;
  width: 100px;
  height: 40px;
  resize: none;
  font-size: 18px;
  line-height: 20px;
  margin: auto 0;
  border: 1px solid grey;
  border-radius: 10px;
  direction: rtl;
  text-align: center;
  cursor: pointer;
}

.worker__name:hover {
  outline: 2px solid coral;
}

.worker__name_edit {
  border: 2px solid cornflowerblue;
  cursor: text;
}

.worker__name_edit:hover {
  outline: none;
}

.worker__name:disabled {
  color: black;
}

.worker__textarea {
  width: 25vw;
  max-width: 300px;
  height: 100px;
  border: 1px solid grey;
  border-radius: 10px;
  margin: 0 auto;
  font-size: 18px;
  resize: none;
  text-align: center;
}

.worker__textarea:disabled {
  color: black;
}

.morning {
  background-color: white;
  margin-top: 5px;
}

.night {
  background-color: whitesmoke;
  margin-bottom: 5px;
}

.worker__textarea_edit {
  border: 2px solid cornflowerblue;
}
