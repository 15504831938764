.header {
  background-color: rgb(80, 80, 80);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
  padding: 10px 0;
}

.header__title {
  margin: 20px 0 0;
}

.header__buttons {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}

.header__button {
  width: 110px;
  background-color: transparent;
  color: white;
  box-shadow: -4px -4px 2px #909090 inset;
  border: 1px solid white;
  border-radius: 8px;
  font-size: 22px;
  font-weight: 700;
  padding: 5px 10px 10px 5px;
  cursor: pointer;
  transition: all 50ms linear;
}

.header__button:hover {
  color: rgb(0, 200, 0, 0.7);
}

.header__button_active,
.header__button:active {
  color: rgb(0, 200, 0);
  box-shadow: 4px 4px 2px #909090 inset;
  padding: 10px 5px 5px 10px;
}

.header__button_cancel {
  color: orangered;
}

.header__button_cancel:hover {
  color: rgb(255, 69, 0, 0.7);
}

.header__edit_active:hover {
  color: orange;
}

.header__icon {
  margin: 0 0 20px;
}
