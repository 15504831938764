.add-button {
  background-color: #ccc;
  color: black;
  font-size: 20px;
  border-radius: 25px;
  height: 40px;
  margin: 0 auto 50px;
  padding: 0 20px;
  cursor: pointer;
}

.add-button:hover {
  background-color: #eee;
  transform: scale(1.1);
  transition: transform 200ms linear;
}
